import clsx from 'clsx'
import {FC, useState} from 'react'
import {usePopper} from 'react-popper'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/models'
import {RootState} from '../../../../setup'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

export const HeaderUserMenu: FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const [showPopper, setShowPopper] = useState(false)
    const [referenceElement, setReferenceElement] = useState<any>(null)
    const [popperElement, setPopperElement] = useState<any>(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    })
    return (
        <>
            {showPopper && (
                <div
                    className='w-100 h-100'
                    style={{
                        zIndex: 0,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                    onClick={() => setShowPopper(false)}
                />
            )}

            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                style={{zIndex: 1}}
            >
                <button
                    ref={(ref) => setReferenceElement(ref)}
                    onClick={() => setShowPopper((oldValue) => !oldValue)}
                    className={clsx(
                        'cursor-pointer symbol btn btn-icon btn-active-success',
                        toolbarUserAvatarHeightClass
                    )}
                >
                    <i className={toolbarButtonIconSizeClass + ' fas fa-user fa-2x text-white'}></i>
                </button>
                {showPopper && (
                    <div
                        className='menu menu-column menu-rounded rounded bg-white menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px shadow'
                        ref={(ref) => setPopperElement(ref)}
                        style={{...styles.popper, marginLeft: -16}}
                        {...attributes.popper}
                    >
                        <div className='menu-item px-3'>
                            <div className='menu-content d-flex align-items-center px-3'>
                                <div className='symbol symbol-50px me-5'>
                                    <span className='symbol symbol-35'>
                                        <span className='symbol-label font-size-h5 font-weight-bold'>
                                            {user.name?.split('')[0]}
                                        </span>
                                    </span>
                                </div>

                                <div className='d-flex flex-column'>
                                    <div className='fw-bolder d-flex align-items-center fs-5'>
                                        {user.name}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='separator my-2'></div>

                        <div className='menu-item px-5'>
                            <Link
                                to={'/perfil'}
                                className='menu-link px-5 text-black'
                                onClick={() => setShowPopper((oldValue) => !oldValue)}
                            >
                                Meu perfil
                            </Link>
                        </div>
                        <div className='separator my-2' />
                        <div className='menu-item px-5'>
                            <Link
                                to='/evento'
                                className='menu-link px-5 text-black'
                                onClick={() => setShowPopper((oldValue) => !oldValue)}
                            >
                                Meu evento
                            </Link>
                        </div>
                        <div className='separator my-2' />
                        <div className='menu-item px-5'>
                            <Link
                                to='/logout'
                                className='menu-link px-5'
                                onClick={() => setShowPopper((oldValue) => !oldValue)}
                            >
                                Sair
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
