import {FC} from 'react'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'

const Topbar: FC = () => {
    return (
        <section className='h-100 d-flex'>
            <div className='d-flex align-items-stretch flex-shrink-0'>
                <HeaderNotificationsMenu />
                <HeaderUserMenu />
            </div>
        </section>
    )
}

export {Topbar}
