/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import store from '../../../../setup/redux/Store'

export function HeaderWrapper() {
    const {pathname} = useLocation()
    const {config, classes, attributes} = useLayout()
    const {header, aside} = config

    const {
        auth: {event_code, nm_event},
    } = store.getState()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [pathname])

    return (
        <div
            id='kt_header'
            className={clsx('header', classes.header.join(' '), 'align-items-stretch bg-primary')}
            {...attributes.headerMenu}
        >
            <div
                className={clsx(
                    classes.headerContainer.join(' '),
                    'd-flex align-items-stretch justify-content-between'
                )}
            >
                {/* begin::Aside mobile toggle */}
                {aside.display && (
                    <div
                        className='d-flex align-items-center d-lg-none ms-n3 me-1'
                        title='Show aside menu'
                    >
                        <div
                            className='btn btn-icon btn-active-success w-30px h-30px w-md-40px h-md-40px'
                            id='kt_aside_mobile_toggle'
                        >
                            <i className='fas fa-bars text-white fs-1'></i>
                        </div>
                        <span
                            style={{maxWidth: '250px'}}
                            className='d-flex align-items-center justify-content-start text-white h2 p-0 ms-2 mb-0 h-100 w-100 text-nowrap overflow-auto'
                        >
                            {nm_event}
                        </span>
                    </div>
                )}
                {/* end::Aside mobile toggle */}

                {/* begin::Wrapper */}
                <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
                    <span className='d-lg-flex d-none align-items-center justify-content-start text-white h2 p-0 mb-0 h-100'>
                        {nm_event} ({event_code})
                    </span>
                    {/* begin::Navbar */}
                    {header.left === 'menu' && (
                        <div className='d-flex align-items-stretch' id='kt_header_nav'>
                            <Header />
                        </div>
                    )}

                    {header.left === 'page-title' && (
                        <div className='d-flex align-items-center' id='kt_header_nav'>
                            <DefaultTitle />
                        </div>
                    )}

                    <div className='d-flex align-items-stretch flex-shrink-0'>
                        <Topbar />
                    </div>
                </div>
                {/* end::Wrapper */}
            </div>
        </div>
    )
}
